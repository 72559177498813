import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Footer.scss';
import Logo from './Logo';

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Получаем текущий год

  const emailInfo = "info@vnutrix.ru";
  const emailPartners = "partners@vnutrix.ru";

  return (
    <footer className="text-center text-lg-start">
      <section id="contacts" >
        <Container fluid className="p-4">
          <Container className='border-top border-top-secondary border-bottom py-5'>
            <Row>
              <Col xs lg="3">
                <h1>
                  <Logo />
                </h1>
              </Col>
              <Col xs lg="9">
                <h2 className='text-start mb-4 fw-bold'>Контакты</h2>
                <Row>
                  <Col>
                  <div className='d-flex flex-column text-start'>
                  <span>
                    Общая информация:
                  </span>
                    <Button variant="link" className='p-0 me-auto' href={`mailto:${emailInfo}`}>{emailInfo}</Button>
                  </div>
                  </Col>
                  <Col>
                  <div className='d-flex flex-column text-start'>
                  <span>
                    Корпоративным клиентам:
                  </span>
                    <Button variant="link" className='p-0 me-auto' href={`mailto:${emailPartners}`}>{emailPartners}</Button>
                  </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
      </section>
      <div className="text-center px-3 py-1" style={{ fontSize: '0.8rem' }}>
        ООО «Нутриномика», ©{currentYear}
      </div>
    </footer>
  );
};


export default Footer;