import React from 'react';
import './Logo.scss'; // Импортируем стили для логотипа

const Logo = () => {
  return (
    <span className="logo">
      <strong>v</strong>
      <strong className="red-letter">N</strong>
      <strong>utri</strong>
    </span>
  );
};

export default Logo;