import React from 'react';
import './ProductCard.scss';

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
  return images;
};
const icons = importAll(require.context('../assets/products-icons', false, /\.(png|jpe?g|svg)$/));

const ProductCard = ({ title, text, iconName, extraClassName }) => {
  return (
    <div className={`product-card-icon ${extraClassName ? extraClassName : ''}`}>
      <img className='product-card-icon-img' src={icons[iconName]} alt={title} />
      <div className="product-card-icon-title">
        <span>{title}</span>
        <div className="product-card-icon-body">{text}</div>
      </div>
    </div>
  );
};

export default ProductCard;