import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { useState, useEffect, useCallback } from 'react';
import Logo from './Logo';

function Header() {
  const [scrolling, setScrolling] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const scrollingOffset = 30;

  const handleScroll = useCallback(() => {
    const offset = window.scrollY;
    setScrolling(offset > 50);

    const sections = document.querySelectorAll('section');
    let currentSection = '';

    sections.forEach(section => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.clientHeight;

      if (offset >= sectionTop - sectionHeight / 3 && offset < sectionTop + sectionHeight / 3) {
        currentSection = section.getAttribute('id');
      }
    });

    if (currentSection && currentSection !== activeSection) {
      setActiveSection(currentSection);
    }
    if (offset === 0) {
      setActiveSection('');
    }
    else if (window.innerHeight + offset >= document.body.offsetHeight) {
      setActiveSection('contacts');
    }
  }, [activeSection]);

  const handleScrollTo = (event, anchor) => {
    event.preventDefault();
    const element = document.getElementById(anchor);
    const navbarHeight = document.querySelector('.navbar').offsetHeight;
    const offsetPosition = element.getBoundingClientRect().top + window.pageYOffset - navbarHeight - scrollingOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <Navbar className={`navbar ${scrolling ? 'bg-white' : 'bg-transparent'}`} expand="lg" fixed="top">
      <Container>
        <Navbar.Brand href="#home">
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className={scrolling ? 'bg-white' : ''}>
          <Nav className="ms-auto gap-4">
            <Nav.Link href="#technologies" className={activeSection === 'technologies' ? 'active' : ''} onClick={(e) => handleScrollTo(e, 'technologies')}>Технологии</Nav.Link>
            <Nav.Link href="#products" className={activeSection === 'products' ? 'active' : ''} onClick={(e) => handleScrollTo(e, 'products')}>Наши продукты</Nav.Link>
            <Nav.Link href="#contacts" className={activeSection === 'contacts' ? 'active' : ''} onClick={(e) => handleScrollTo(e, 'contacts')}>Контакты</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;