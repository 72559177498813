import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.scss';
import firstBg from './assets/food-plate.png';
import foodPerson from './assets/food-percon.png';
import Logo from './components/Logo';
import ProductCard from './components/ProductCard';

function App() {
  return (
    <div >
      <Header />
      <Container fluid className="pt-5 position-relative">
        <Container>
          <Row className="first-screen align-items-center">
            <Col lg={7} className="text-start">
              <h1 className='display-1'><Logo /></h1>
              <p className='mb-0 fs-1'>Персональные траектории питания</p>
              <p className='mb-0 fs-2'>AI подходы в области пищевой аналитики</p>
            </Col>
            <Col lg={5} className="text-center d-none d-lg-flex">
              <img src={firstBg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </Container>
        <div className='first-screen-svg-shade'></div>
        <div className='first-screen-bg-color'></div>
      </Container>

      <section id="technologies" className='mt-5 pt-lg-5'>
        <Container className="py-lg-5">
          <Row>
            <Col lg={6}>
              <img src={foodPerson} alt="" className="img-fluid" />
            </Col>
            <Col lg={6} className="text-start fs-3">
              <p><Logo /> — компания, создающая персональные траектории питания с помощью генеративного искусственного интеллекта. AI подходы в области пищевой аналитики.</p>
              <p>Новатор в области искусственного интеллекта, аналитики данных, MLOps и облачных вычислений.</p>
              <p>Использует самые современные технологии обработки данных, естественного языка и математические модели из мира теории оптимизации и предиктивной аналитики.</p>
            </Col>
          </Row>
        </Container>

        <Container fluid className="bg-tech py-lg-5 mt-5" style={{ position: 'relative' }}>
            <div className="overlay"></div>
              <Container className='position-relative'>
          <Row className="d-flex align-items-center py-5">
                <div className="my-lg-5 py-lg-5 bg-tech-title" style={{ width: '80%', marginRight: 'auto' }}>Наше ноу-хау<br />AI алгоритмы подсчета микронутриентов в продуктах</div>
                <div>
                <div className='bg-tech-code'><pre>
                  <code>
                    {`
model = NutrientsPredictor(*args, **kwargs)
model.load_state_dict(torch.load(PATH))
model.eval()

products_data = import_from_database(PRODUCTS_PATH)
for product in products_data: 
    name = product['name']
    `}
    <span className="accent">nutrients</span> {` = model.predict(product)
    nutrients_list.append({'name': name, '`}<span className="accent">nutrients</span>{`': nutrients})
    `}

                  </code>
                </pre> </div>
                <div className='bg-tech-code bottom'> <pre>
                  <code>
                  <span className="accent">nutrients_list</span>{` = format_data_for_storage(nutrients_list) `}
{`
for `}<span className="accent">nutrients</span>{` in nutrient_list:`}
{`
    database.session.add(nutrients)
    database.session.commit() `}
                  </code>
                </pre>
                </div>

                </div>
          </Row>
              </Container>
        </Container>
      </section>

      <section id="products">
        <Container className="mt-5">
          <h2 className='mb-4 fw-bold'>Наши продукты</h2>
          <Row className='justify-content-center align-items-center gap-2'>
            <Col lg={5} className='text-lg-end'>
              <div className='d-flex align-items-center product-card'>
                <div>
                  <h3 className='product-title-left mb-3'>Инструменты <br /><span className='fs-5'>для пользователя</span></h3>
                  <ProductCard extraClassName='product-card-icon-left' title="Мобильное приложение" text="Ведите журнал питания, автоматически импортируйте данные с носимых и других устройств и открывайте персонализированные рекомендации прямо на экране." iconName="smartphone.png" />
                  <ProductCard extraClassName='product-card-icon-left' title="Состав продуктов" text="Узнайте микронутриентный состав комплексных продуктов" iconName="database.png" />
                </div>
              </div>
            </Col>
            <Col lg={5} className=''>
              <div className='d-flex align-items-center product-card'>
                <div>
                  <h3 className='product-title-right mb-3'>Решения<br /><span className='fs-5'>для бизнеса</span></h3>
                  <ProductCard title="Интеграция данных" text="Используя API или SDK-интеграции, партнеры могут использовать огромные знания vNutri о продуктах, питании и людях для улучшения собственных продуктов и услуг." iconName="search.png" />
                  <ProductCard title="Пользовательские наборы данных" text="База знаний vNutri обеспечивает интеграцию десятков баз данных из различных источников. Эти данные могут быть разобраны и предоставлены в соответствии с тем, что вам нужно для развития вашего бизнеса." iconName="server.png" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
}

export default App;